import React, { Component, useEffect, useState } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
// import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { nonEmptyArray, composeValidators, required } from '../../util/validators';
import {
  AddImages,
  Button,
  Form,
  ValidationError,
  FieldTextInput,
  FieldSelect,
  FieldDateInput,
  FieldCurrencyInput,
} from '../../components';
import config from '../../config';

import css from './ModalProposalForm.module.css';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import moment from 'moment';
import pdfimage from '../../assets/pdfimage.png';
import ppticon from '../../assets/ppticon.png';
import docicon from '../../assets/docImage.png';
import exlicon from '../../assets/exlImage.png';
import { apiBaseUrl, removeFileFromAzure } from '../../util/api';
import axios from 'axios';

const { paymentTerms } = config.custom;
const ACCEPT_FILE = 'application/pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.xml,.odp';
const identity = v => v;
export class ModalProposalFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadAttachmentToAwsRequested: false,
      attachmentDeleteRequested: false,
      uploadedAttachmentsUrls: [],
      stopLoop: false,
      files: ['nice.pdf', 'verycool.jpg'],
      imageUploadRequested: false,
    };
    this.submittedImages = [];
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        // imageUploadRequested={this.state.imageUploadRequested}
        render={formRenderProps => {
          const {
            form,
            className,
            handleSubmit,
            intl,
            invalid,
            disabled,
            ready,
            values,
            documents,
            listingId,
            onClose,
            inProgress,
          } = formRenderProps;
          const [selectedCurrency, setSelectedCurrency] = useState(currencyFeeTemp[0].key);

          const reasonLabel = intl.formatMessage({
            id: 'ModalProposalForm.reasonLabel',
          });
          const reasonPlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.reasonPlaceholder',
          });
          const reasonRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.reasonRequiredMessage',
          });

          const scopeOfWorkLabel = intl.formatMessage({
            id: 'ModalProposalForm.scopeOfWorkLabel',
          });
          const scopeOfWorkPlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.scopeOfWorkPlaceholder',
          });
          const scopeOfWorkRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.scopeOfWorkRequiredMessage',
          });

          const methodologyLabel = intl.formatMessage({
            id: 'ModalProposalForm.methodologyLabel',
          });
          const methodologyPlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.methodologyPlaceholder',
          });
          const methodologyRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.methodologyRequiredMessage',
          });
          const currencyFee = intl.formatMessage({
            id: 'ModalProposalForm.currencyFee',
          });
          const currencyFeePlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.currencyFeePlaceholder',
          });
          const currencyFeeRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.currencyFeeRequiredMessage',
          });
          const feeLabel = intl.formatMessage({
            id: 'ModalProposalForm.feeLabel',
          });
          const feePlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.feePlaceholder',
          });
          const feeRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.feeRequiredMessage',
          });

          const paymentTermsLabel = intl.formatMessage({
            id: 'ModalProposalForm.paymentTermsLabel',
          });
          const paymentTermsPlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.paymentTermsPlaceholder',
          });
          const paymentRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.paymentTermsRequiredMessage',
          });

          const documentsLabel = intl.formatMessage({
            id: 'EditListingAdditionalForm.documentsLabel',
          });
          const documentsPlaceholder = intl.formatMessage({
            id: 'EditListingAdditionalForm.documentsPlaceholder',
          });

          const startDateLabel = intl.formatMessage({
            id: 'ModalProposalForm.startDateLabel',
          });
          const startDatePlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.deadlinePlaceholder',
          });
          const startDateRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.startDateRequiredMessage',
          });

          const deadlineLabel = intl.formatMessage({
            id: 'ModalProposalForm.deadlineLabel',
          });
          const deadlinePlaceholder = intl.formatMessage({
            id: 'ModalProposalForm.deadlinePlaceholder',
          });
          const deadlineRequiredMessage = intl.formatMessage({
            id: 'ModalProposalForm.deadlineRequiredMessage',
          });

          const submitLabel = intl.formatMessage({
            id: 'ModalProposalForm.submit',
          });
          const cancelLabel = intl.formatMessage({
            id: 'ModalProposalForm.cancel',
          });

          const classes = classNames(css.root, className);

          const submitDisabled = invalid || inProgress;
          // || !(documents && documents.length);

          const handleChange = ({ values, modified }) => {
            const currencyFee = values?.currencyFee || null;

            if (currencyFee && currencyFee !== selectedCurrency) {
              setSelectedCurrency(currencyFee);

              if (modified && modified.currencyFee) {
                form.change('paymentFee', null);
              }
            }
          };

          const isDayBlocked = day =>
            values && values.startDate && moment(values.startDate.date).isSameOrAfter(moment(day));

          const onAttachmentUpload = async(file) => {
            // this.props.setClearForm(false);
            if (file && file.name) {
              const formData = new FormData();
              formData.append('file', file);
              const {data={}} = await axios.post(`${apiBaseUrl()}/api/upload-file-aws`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              
              const updateduploadedAttachmentsUrls = [...this.state.uploadedAttachmentsUrls];
              const { location,key } = data;
              const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');
              updateduploadedAttachmentsUrls.push({
                link: location,
                date: currentDate,
                name: file.name,
                size: file.size,
                key
              });
              this.setState({
                uploadedAttachmentsUrls: updateduploadedAttachmentsUrls,
                attachmentDeleteRequested: false,
                uploadAttachmentToAwsRequested: false,
              });
            }
          };
          const onRemoveFileLinks = async (index, item) => {
            if (index > -1) {
              await removeFileFromAzure({ key: item.key })
              this.state.uploadedAttachmentsUrls.splice(index, 1);
              this.setState({ uploadedAttachmentsUrls: this.state.uploadedAttachmentsUrls });
            }
          };
          if (this.state.uploadedAttachmentsUrls && this.state.uploadedAttachmentsUrls.length) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (this.state.attachmentDeleteRequested) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (values['attachmentsUrls'] && values['attachmentsUrls'].length) {
            this.setState({ uploadedAttachmentsUrls: values['attachmentsUrls'] });
          }
          const { uploadedAttachmentsUrls } = this.state;

          function handleMoneyFormat(value, key) {
            const modifiedValue = value.toLocaleString("en-US")
            if (Number.isInteger(value)) form.change(key, modifiedValue)
            if (value === 0) form.change(key, '')
          }

          return (
            <Form
              className={classes}
              onSubmit={e => {
                // this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              <FormSpy onChange={handleChange} subscription={{ modified: true, values: true }} />

              <FieldTextInput
                id="reason"
                name="reason"
                className={css.oneRowTextInput}
                type="text"
                label={reasonLabel}
                placeholder={reasonPlaceholder}
                validate={required(reasonRequiredMessage)}
              />
              <FieldTextInput
                id="scopeOfWork"
                name="scopeOfWork"
                className={css.oneRowTextInput}
                type="text"
                label={scopeOfWorkLabel}
                placeholder={scopeOfWorkPlaceholder}
                validate={required(scopeOfWorkRequiredMessage)}
              />
              <FieldTextInput
                id="methodology"
                name="methodology"
                className={css.oneRowTextInput}
                type="text"
                label={methodologyLabel}
                placeholder={methodologyPlaceholder}
                validate={required(methodologyRequiredMessage)}
              />
              <div className={css.imagesField}>
                <label className={css.imageLabel}>{documentsLabel}</label>
                <Field
                  label={documentsPlaceholder}
                  id="document"
                  name="document"
                  accept={ACCEPT_FILE}
                  form={null}
                  type="file"
                >
                  {fieldprops => {
                    const { accept, input, label, meta, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      if (file && file.name) {
                        this.setState({ uploadAttachmentToAwsRequested: true, stopLoop: false });
                        onAttachmentUpload(file);
                      }
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </div>
              <ul className={css.uploadDoc}>
                {uploadedAttachmentsUrls &&
                  uploadedAttachmentsUrls.map((item, index) => {
                    const fileTypeExt = item.name.split('.')[1];

                    return (
                      item === null ? (
                        <></>
                      ) : (
                          <li key={item.key}>
                            <div
                              className={css.documentBox}
                            >
                              <div className={css.documentImage}>
                                {fileTypeExt?.includes("pdf") ? (
                                  <img src={pdfimage} />
                                ) : ['doc', 'docs', 'docx'].includes(fileTypeExt) ? (
                                  <img src={docicon} />
                                ) : ['exl', 'xlsx'].includes(fileTypeExt) ? (
                                  <img src={exlicon} />
                                ) : fileTypeExt?.includes('ppt', 'pptx') ? (
                                  <img src={ppticon} />
                                ) : ['csv'].includes(fileTypeExt) ? (
                                  <IconCard brand="csv" />
                                ) : ['zip'].includes(fileTypeExt) ? (
                                  <IconCard brand="zip" />
                                ) : null}
                              </div>
                              <div>
                                <div className={css.documentName}>{item && item.name}</div>
                                <div className={css.documentDate}>{item && item?.date}</div>
                              </div>
                              <span className={css.closer} onClick={() => onRemoveFileLinks(index,item)}>
                                {' '}
                              </span>
                            </div>
                          </li>
                      )
                    )
                  })}
              </ul>

              <div className={css.rowInputWrapper}>
                <div className={css.wrapperCurrencyFee}>
                  <FieldSelect
                    id="currency"
                    name="currency"
                    className={css.currencySelector}
                    label={currencyFee}
                    validate={required(currencyFeeRequiredMessage)}
                    defaultValue={currencyFeeTemp[0].key}
                  >
                    {currencyFeeTemp.map(({ key, label, symbol }) => (
                      <option key={label} value={key}>
                        {label}
                        {symbol}
                      </option>
                    ))}
                  </FieldSelect>

                  <div>
                     <label>Fee</label>
                    <FieldTextInput
                      onChange={(e) => {
                        const value = Number(e.target.value.replace(/\,/g, ''))
                        handleMoneyFormat(value, 'amount')
                      }}
                      type="text"
                      id='amount'
                      name='amount'
                      placeholder={'0'}
                      validate={required(feeRequiredMessage)}
                    />
                  </div>
                </div>
                <FieldSelect
                  id="paymentTerms"
                  name="paymentTerms"
                  className={css.halfRowTextInput}
                  label={paymentTermsLabel}
                  validate={required(paymentRequiredMessage)}
                >
                  <option value="" disabled>
                    {paymentTermsPlaceholder}
                  </option>
                  {paymentTerms.map(({ key, label }) => (
                    <option key={label} value={key}>
                      {label}
                    </option>
                  ))}
                </FieldSelect>
              </div>

              <div className={css.rowInputWrapper}>
                <FieldDateInput
                  id="startDate"
                  name="startDate"
                  className={css.fieldDateInput}
                  label={startDateLabel}
                  format={identity}
                  placeholderText={moment().format('DD/MM/YYYY')}
                  validate={required(startDateRequiredMessage)}
                />

                <FieldDateInput
                  id="deadline"
                  name="deadline"
                  className={css.fieldDateInput}
                  isDayBlocked={isDayBlocked}
                  label={deadlineLabel}
                  format={identity}
                  placeholderText={moment().format('DD/MM/YYYY')}
                  validate={required(deadlineRequiredMessage)}
                />
              </div>
              <div className={css.buttonWrapper}>
                <Button
                  className={css.cancelButton}
                  type="button"
                  onClick={onClose}
                  disabled={submitDisabled}
                // inProgress={inProgress}
                >
                  {cancelLabel}
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  disabled={submitDisabled}
                  inProgress={inProgress}
                >
                  {submitLabel}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ModalProposalFormComponent.defaultProps = { fetchErrors: null, images: [] };

export default compose(injectIntl)(ModalProposalFormComponent);
