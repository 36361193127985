import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form, FieldTextInput, PrimaryButton, SecondaryButton, FieldCheckbox, IconCard, IconSpinner, Modal, SkeletonLoader, CaptureRunNameField } from '../../components';
import css from './OpenAIContentAnalysisForm.module.css';
import { addGigAppSession, apiBaseUrl, fetchQueryReportData, generateAppDataFromOpenAI, handleFileUpload, updateOpenAIData } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import MappedQuestions from './MappedQuestions';
import axios from 'axios';
import useHandleFileExtensionError from '../../hooks/useHandleFileExtensionError';
import FieldValidationErrorMessage from '../../components/FieldValidationErrorMessage/FieldValidationErrorMessage';


const ACCEPT_FILE = '.doc,.docx,application/pdf';
const LANGUAGES = [
  { key: 'english', label: 'English' },
  { key: 'spanish', label: 'Spanish' },
  { key: 'french', label: 'French' },
  { key: 'german', label: 'German' },
  { key: 'italian', label: 'Italian' },
  { key: 'portuguese', label: 'Portuguese' },
  { key: 'dutch', label: 'Dutch' },
  { key: 'russian', label: 'Russian' },
  { key: 'chinese', label: 'Chinese' },
  { key: 'korean', label: 'Korean' },
];

//Alert Modal Types - 
const MINIMUM_QUESTION_PROCESS_ALERT = 'minimumQuestionProcessAlert';
const QUESTION_PROCESSING_ALERT = 'questionProcessingAlert';
const RESET_ALL_ALERT = 'resetAllAlert';
const FILE_EXTENSION_ERROR_MESSAGE = 'Please select files in PDF, DOC, or DOCX format only.';
const LANGUAGE_ERROR_MESSAGE = 'Please select a language first'
const FILE_ERROR_MESSAGE = 'Please choose a file first'

export const OpenAIContentAnalysisForm = props => {

  const InputFileComponent = ({ form, id, files=[], isMultiple, displayFieldError }) => {
    const [error, setError] = useState(false);
    const [fileExtensionError, checkForFileExtension] = useHandleFileExtensionError();

    const handleFileExtensionError = (message) => {
      setError(message)
      setTimeout(() => {
        setError(false);
      }, 3000);
    };

    return (
      <div className={css.inputAttachment}>
        {((isMultiple && files.length<50) || files?.length===0) && (
          <>
            <Field
              id={id}
              name={id}
              accept={ACCEPT_FILE}
              type="file"
              multiple={isMultiple}
            >
              {fieldprops => {
                const { accept, input } = fieldprops;
                const { name, type, multiple } = input;
                const onChange = async (e) => {
                  let filesObject = Object.values(e.target.files);

                  const allowedExtensions = /(\.pdf|\.doc|\.docx)$/i
                  let extensionError = checkForFileExtension(allowedExtensions, filesObject, FILE_EXTENSION_ERROR_MESSAGE)
                  if(extensionError) return
                  if((filesObject?.length+files?.length) > 50){
                    handleFileExtensionError(`You can only choose files more than 1 and less than 50`)
                    return
                  }

                  form.change(id, [...(files || []),...Object.values(filesObject)])
                  form.change('gigAppInputFiles', [...(files || []),...filesObject])
                };
                const inputProps = { accept, id: name, name, onChange, type, multiple };
                return <input {...inputProps} className={css.addImageInput} />
              }}
            </Field>
            <label htmlFor={id} className={css.attachmentLabel}>
              <IconCard brand="upload" />
              <span className={css.dragDrop}><FormattedMessage id={id==="transcripts" ? "GigAppsPage.onlyMultipleFilesUpload" : "GigAppsPage.singleFileUpload"} /></span>
              <span className={css.docType}>
                <FormattedMessage id="OpenAIResearchInstrumentForm.allowedResearchDocumentFormat" />
              </span>
            </label>
            {fileExtensionError && <div className={css.errorMessage}>{fileExtensionError}</div>}
            {displayFieldError && <FieldValidationErrorMessage message={FILE_ERROR_MESSAGE}/>}
            {error && <div className={css.errorMessage}>{error}</div>}
            {(isMultiple && files.length>0 && files.length<2) && (
              <div className={css.errorMessage}><FormattedMessage id="OpenAIContentAnalysisForm.errorMessage" /></div>
            )}
          </>
        )}
        <div className={css.fileViewContainer}>
          {files?.length>0 && files?.map((file,index) => (
            <React.Fragment key={file.name}>
              <FileView file={file}>
                <span className={css.removeFile} onClick={() => {
                  const modifiedFiles = [...files]
                  modifiedFiles.splice(index, 1)
                  form.change(id, modifiedFiles)
                  form.change('gigAppInputFiles', modifiedFiles)
                }}>
                  <FormattedMessage id="ZohoSignForm.remove" />
                </span>
              </FileView>
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          pristine,
          updated,
          values,
          form,
          storeUserInput,
          storeModifiedQuestion,
          uniqueInputId,
          handleRedirectToGigAppsPage,
          currentUserEmail,
          appRoute,
          manageDisableScrolling,
          firstName,
          updateAppUsageData,
          boxFolder,
          appData,
          transactionId,
          timeZone,
          appTitle
        } = formRenderProps;

        const params = useParams();
        // const submitDisabled = !values.requirement || !values.budget || !values.timeline;
        const [questions, setQuestions] = useState([]);
        const [uploadInProgress, setUploadInProgress] = useState(false);
        const transactionTab = "gig-app";
        const isAssistedTransaction = params?.tab === transactionTab;

        const {
          runName,
          step = isAssistedTransaction ? 1 : params?.id ? 4 : 1,
        } = values || {};
        const setStep = (value) => form.change('step', value);
 
        const [error, setError] = useState(false);
        const [alertModal, setAlertModal] = useState(false);
        const [displayFieldError, setDisplayFieldError] = useState(false);

        function handleError(message) {
          setError(message)
          setTimeout(() => setError(false), 3000)
        }
        
        function handleFieldError(){
          setDisplayFieldError(true)
          setTimeout(() => setDisplayFieldError(false), 3000)
        }

        function getSubmitDisabledValue() {
          switch (step) {
            case 2: return !values.selectedLanguage;
            case 3: return !values.discussionDocument;
            case 4: return false;
            case 5: return !values.transcripts || values?.transcripts?.length<2
          }
        }

        function handleNextClick() {
          if (step === 3) handleDiscussionAttachment(values.discussionDocument[0], values.gigAppInputFiles[0])
          else if (step === 4) handleStoreUpdatedQuestion()
          else if (step === 5) handleTranscriptUpload(values.gigAppInputFiles)
          else setStep(step + 1)
        }

        async function handleDiscussionAttachment(file, inputFile) {
          try {
            if (!file?.name) return
            setUploadInProgress(true)

            const response = await handleFileUpload('get-parsed-document', { file }) // get document text

            if (response.status === 200 && response?.data) {
              const data = {
                fileName: file?.name,
                size: file?.size,
                createdAt: new Date().toISOString(),
                selectedLanguages: values.selectedLanguage,
                isQuestionsReady: false,
                title: appTitle
              }
              
              const formData =  new FormData
              formData.append('appRoute', appRoute)
              formData.append('firstName', firstName)
              formData.append('email', currentUserEmail)
              formData.append('runName', runName)

              // Store session in mongo
              if(isAssistedTransaction){
                const appSessionResponse = await addGigAppSession({ 
                  rootFolderId: boxFolder.rootFolderId, 
                  transactionId: params?.id, 
                  files: [{name: file?.name, size: file?.size}], 
                  data, runName, appRoute,
                  email: currentUserEmail,
                });
                formData.append('data',JSON.stringify({
                  ...data,
                  id: appSessionResponse?.sessionId,
                  text: response?.data,
                  transaction: params?.id
                }))
                formData.append('file',inputFile)
              }
              else{
                handleFileUpload('upload-gigapp-input-file-aws', { file: inputFile, email: currentUserEmail })
                await storeUserInput(data, runName)

                //Get generated questions from uploaded document text
                formData.append('data',JSON.stringify({
                  ...data,
                  id: uniqueInputId,
                  text: response?.data
                }))
              }

              axios.post(`${apiBaseUrl()}/api/question-heading-generator`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              );
              setUploadInProgress(false)
              setAlertModal(QUESTION_PROCESSING_ALERT)

              if(!isAssistedTransaction) updateAppUsageData()
            }
            else throw new Error('Erorr: Failed to upload. Try Again!');
          } catch (error) {
            handleError(error?.message)
            setUploadInProgress(false)
          }
        }

        async function handleStoreUpdatedQuestion() {
          setUploadInProgress(true)

          if (questions.length) {
            const modifiedQuestions = questions.map(item => {
              return { textFromDG: item?.originalQuestion, question: item?.modifiedQuestion }
            })

            const response = await storeModifiedQuestion(isAssistedTransaction ? appData?.id : params?.id, modifiedQuestions, isAssistedTransaction)
            if (response.status === 'success') {
              setUploadInProgress(false)
              setStep(5)
            }
          }
        }

        async function handleTranscriptUpload(inputFile) {
          setUploadInProgress(true)

          //update inprogress varaible to true in database
          await updateOpenAIData({ type: appRoute, id: params?.id })

          const files = values.transcripts;
          const formData = new FormData()
          formData.append('email', currentUserEmail)
          formData.append('sessionId', isAssistedTransaction ? appData?.id : params?.id)
          formData.append('firstName', firstName)
          formData.append('appRoute', appRoute)
          formData.append('runName', runName)
          formData.append('timeZone', timeZone)
          if(isAssistedTransaction)formData.append('transactionId', transactionId)
          
          for (let key in files) {
            formData.append('files', files[key]);
          }

          axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/content_analysis_text_splitter`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          
          setTimeout(() => {
            setUploadInProgress(false)
            setStep(6)
          }, 3000)
        }

        async function fetchData() {
          const response = await fetchQueryReportData({
            type: appRoute,
            email: currentUserEmail,
            id: isAssistedTransaction ? appData?.id : params?.id,
            isTransaction: isAssistedTransaction
          });
          if (response.status === 'success') {
            const data = response.data?.data;
            // if(data.reason !== 'Yes') setAlertModal(MINIMUM_QUESTION_PROCESS_ALERT)
            const modifiedQuestionArray = data.questions?.map(item => ({
              originalQuestion: typeof item === 'string' ? item : item?.textFromDG,
              generatedQuestion: typeof item === 'string' ? item : item?.question,
              modifiedQuestion: typeof item === 'string' ? item : item?.question,
            }))
            setQuestions(modifiedQuestionArray)
            form.change('runName', response?.data?.runName)
          }
        }


        useEffect(() => {
          if(isAssistedTransaction){
            if(appData?.step === 4){
              setStep(4)
              fetchData()
            }
          }else{
            if (currentUserEmail && params?.id) fetchData()
          }
        }, [currentUserEmail, appData])

        async function handleResetAll() {
          fetchData()
          setAlertModal(false)
        }

        return (
          <Form onSubmit={handleSubmit}>
            <div className={classNames(step !== 4 && css.contentAnalysisForm)}>
              <div className={classNames(css.container, step === 4 && css.questionContainer)}>
                <div className={css.heading}>
                  <FormattedMessage id='OpenAIContentAnalysisForm.heading' />
                </div>
                <CaptureRunNameField step={step} form={form} values={values}>
                  {step === 2 ? (
                    <div>
                      <div className={css.stepsLabel}>
                        <span className={css.browserBack} onClick={() => form.change('step', step - 1)}>
                          <IconCard brand="backArrow" />
                        </span>
                        <FormattedMessage id='OpenAIContentAnalysisForm.step1' />
                      </div>
                      <div className={css.languageCheckbox}>
                        {LANGUAGES.map(lan => (
                          <FieldCheckbox
                            key={lan.key}
                            id={lan.key}
                            name='selectedLanguage'
                            value={lan.key}
                            label={lan.label}
                          />
                        ))}
                      </div>
                      <div>
                        {displayFieldError && <FieldValidationErrorMessage message={LANGUAGE_ERROR_MESSAGE} vibrate={true}/>}
                      </div>
                    </div>
                  ) : step === 3 ? (
                    <div>
                      <div className={css.stepsLabel}>
                        <span className={css.browserBack} onClick={() => form.change('step', step - 1)}>
                          <IconCard brand="backArrow" />
                        </span>
                        <FormattedMessage id='OpenAIContentAnalysisForm.step2' />
                      </div>
                      <InputFileComponent form={form} id='discussionDocument' files={values.discussionDocument} />
                    </div>
                  ) : step === 4 ? (
                    <div>
                      <div className={css.stepsLabel}>
                        <FormattedMessage id='OpenAIContentAnalysisForm.step3' />
                      </div>
                      {questions?.length !== 0 ? (
                        <MappedQuestions questions={questions} setQuestions={setQuestions} manageDisableScrolling={manageDisableScrolling} appRoute={appRoute} />
                      ) : (
                        <div className={css.loader}>
                          <SkeletonLoader isMobile={true} />
                        </div>
                      )}
                    </div>
                  ) : step === 5 ? (
                    <div>
                      <div className={css.stepsLabel}>
                        <FormattedMessage id='OpenAIContentAnalysisForm.step4' />
                      </div>
                      <InputFileComponent form={form} id='transcripts' files={values.transcripts} isMultiple={true} />
                    </div>
                  ) : step === 6 && (
                    <div>
                      <div className={css.stepsLabel}>
                        <h3><FormattedMessage id='OpenAIContentAnalysisForm.successStepMessage' /></h3>
                      </div>
                    </div>
                  )}

                  <div className={classNames(css.buttomButtonWrapper, css.singleButtonWrapper)}>
                    {step === 6 ? (
                      <PrimaryButton
                        type='button'
                        className={classNames(getSubmitDisabledValue() ? css.disabledSubmitButton : css.submitButton )}
                        onClick={(e)=>{
                          e.preventDefault()
                          handleRedirectToGigAppsPage()
                        }}
                      >
                        <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                      </PrimaryButton>
                    ) : (
                      <>
                        {step === 4 && (
                          <SecondaryButton
                            type='button'
                            className={css.resetButton}
                            onClick={() => setAlertModal(RESET_ALL_ALERT)}
                          >
                            <FormattedMessage id='OpenAIContentAnalysisForm.resetButtonLabel' />
                          </SecondaryButton>
                        )}
                        <PrimaryButton
                          type='button'
                          className={classNames(getSubmitDisabledValue() ? css.disabledSubmitButton : css.submitButton )}
                          onClick={(e)=>{
                            if(getSubmitDisabledValue()){
                              e.preventDefault()
                              handleFieldError()
                            }else{
                              setDisplayFieldError(false)
                              handleNextClick()
                            }
                          }}
                          >
                          {uploadInProgress ? (
                            <IconSpinner />
                          ) : (
                            <FormattedMessage
                              id={step === 5
                                ? 'OpenAIContentAnalysisForm.submitButtonLabel'
                                : 'OpenAIContentAnalysisForm.nextButtonLabel'
                              }
                            />
                          )}
                        </PrimaryButton>
                      </>
                    )}
                  </div>
                  {error && <p className={css.error}>{error}</p>}
                </CaptureRunNameField>
              </div>
            </div>

            <Modal
              id="alertModal"
              contentClassName={css.containerClassName}
              isOpen={!!alertModal}
              onClose={() => setAlertModal(false)}
              onManageDisableScrolling={manageDisableScrolling}
            >
              <div className={css.alertModal}>
                {alertModal === MINIMUM_QUESTION_PROCESS_ALERT ? (
                  <p>
                    <FormattedMessage id='OpenAIContentAnalysisForm.minimumQuestionProcessAlert' values={{
                      contactEmail: <a href='mailto:admin@insightgig.com'>admin@insightgig.com</a>
                    }} />
                  </p>
                ) : alertModal === QUESTION_PROCESSING_ALERT ? (
                  <>
                    <h2 style={{ textAlign: 'center' }}><FormattedMessage id="OpenAiAppsPanel.successModalHeading" /></h2>
                    <p><FormattedMessage id='OpenAIContentAnalysisForm.questionProcessingAlertMessage' /></p>
                    <PrimaryButton
                      type='button'
                      className={css.closeButton}
                      onClick={handleRedirectToGigAppsPage}
                    >
                      <FormattedMessage id='OpenAIContentAnalysisForm.closeButtonLabel' />
                    </PrimaryButton>
                  </>
                ) : alertModal === RESET_ALL_ALERT ? (
                  <>
                    <h2 style={{ textAlign: 'center' }}><FormattedMessage id='OpenAIContentAnalysisForm.resetAllQuestionAlert' /></h2>
                    <p><FormattedMessage id='OpenAIContentAnalysisForm.resetAllQuestionWarning' /></p>
                    <div className={css.buttonWrapper}>
                      <div className={css.cancelButton} onClick={() => setAlertModal(false)}>
                        Cancel
                      </div>
                      <PrimaryButton
                        type='button'
                        className={css.submitButton}
                        onClick={handleResetAll}
                      >
                        Yes
                      </PrimaryButton>
                    </div>
                  </>
                ) : null}
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  )
}


OpenAIContentAnalysisForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAIContentAnalysisForm);