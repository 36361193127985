import io from 'socket.io-client';
import { storeRawQuantitativeChat } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
import { stopThreadRun } from '../../util/api';
const URL = process.env.REACT_APP_CANONICAL_ROOT_URL; //For staging
// const URL = process.env.REACT_APP_SOCKET_URL; //For local
const socket = io(URL);

export const  handleThreadResponse = (params) => {
  const { 
    id,
    type,
    email,
    threadId,
    question,
    displayQuestion,
    setLastItem,
    setIsLoading,
    handleError,
    isTransaction ,
    datasetSheetName,
    datasetFileId,
    datasetFileName,
    contextFileId,
    contextSheetName,
    contextFileName,
    fileExtension 
  } = params;

  try {
    if (socket) {
      const responseString = `openaiStreamResponse_${threadId}`;
      socket.removeAllListeners(responseString);
      socket.removeAllListeners("quantAppStreamRequest");

      socket.emit('quantAppStreamRequest', { 
        id,
        email,
        question,
        threadId,
        appRoute: type,
        responseString,
        datasetSheetName,
        datasetFileId,
        datasetFileName,
        contextFileId,
        contextSheetName,
        contextFileName,
        fileExtension 
      });

      let answer = ''
      socket.on(responseString, (data) => {
        if(data?.runId) sessionStorage.setItem('runId', data?.runId)

        if (data?.error) {
          throw new Error('Error getting response!');
        } 
        else if (data?.response === 'stream-response-end') {
          const totalTokens = data?.totalTokens;
          // const cleanedAnswer = answer.replace(/(?!<\/?img[^>]*>|<\/?div\s+class=['"]quantAppQueryLoader['"][^>]*>)(>)/g, '').trim();
          storeRawQuantitativeChat({type, id, data: {question: displayQuestion, answer: answer}, isTransaction, totalTokens})
          setIsLoading(false);
          return;
        }
        answer = data?.response
        setLastItem({question: displayQuestion, answer: data?.response});
      });
    }
  } catch (error) {
    // console.log(error, 'Error handling the thread query!');
    handleError(error.message);
  }
};

export async function handleStopThreadStreamResponse(streamId){
  try{
    const responseString = `openaiStreamResponse_${streamId}`;
    if(socket){
      socket.removeAllListeners(responseString);
      
      const runId = sessionStorage.getItem('runId');
      if(runId && streamId){
        stopThreadRun({threadId: streamId, runId})
      }
    }
  }
  catch(error){
    console.log(error, 'Error stopping stream!')
  }
  finally{
    sessionStorage.removeItem('runId')
  }
}