import { fetchCurrentUser } from "../../ducks/user.duck";


export const FETCH_CURRENT_USER_REQUEST = 'app/BecomeInsightGigPartnerPage/FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'app/BecomeInsightGigPartnerPage/FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_ERROR = 'app/BecomeInsightGigPartnerPage/FETCH_CURRENT_USER_ERROR';

export const fetchUserRequest = () => ({
  type: FETCH_CURRENT_USER_REQUEST,
});
export const fetchUserSuccess = (id) => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  payload: { id },
});

export const fetchUserError = e => ({
  type: FETCH_CURRENT_USER_ERROR,
  error: true,
  payload: e,
});

export const loadData = params => async (dispatch, getState) => {
  dispatch(fetchUserRequest());
  try {
    const currentUser = await dispatch(fetchCurrentUser())
    if(currentUser.id){
      dispatch(fetchUserSuccess(currentUser.id));
    }
  } catch (e) {
    dispatch(fetchUserError(e))
    return Promise.all([]);
  }
};