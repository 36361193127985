/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as FavouritesPageLoader } from './FavouritesPage/FavouritesPage.duck';
import { loadData as GigAppsTransactionPageLoader } from './GigAppsTransactionPage/GigAppsTransactionPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as InvitationPageLoader } from './InvitationPage/InvitationPage.duck';
import { loadData as IntegrationPageReducer } from './IntegrationPage/IntegrationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as StripeSubscriptionPageLoader } from './StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { loadData as BecomeInsightGigPartnerPageLoader } from './BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';
import { loadData as FirmPageLoader } from './FirmPage/FirmPage.duck';
import { loadData as FirmDashBoardPageLoader } from './FirmDashBoardPage/FirmDashBoardPage.duck';
import { loadData as FirmMemberExpertsPageLoader } from './FirmMemberExpertsPage/FirmMemberExpertsPage.duck';
import { loadData as ComparisonPageLoader } from './ComparisonPage/ComparisonPage.duck';
import { loadData as AffiliatedFirmPageLoader } from './AffiliatedFirmPage/AffiliatedFirmPage.duck';
import { loadData as DiscourseCommunityForumPageLoader } from './DiscourseCommunityForumPage/DiscourseCommunityForumPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AffiliatedFirmPage: {
      loadData: AffiliatedFirmPageLoader,
    },
    BecomeInsightGigPartnerPage: {
      loadData: BecomeInsightGigPartnerPageLoader,
    },
    FirmPage: {
      loadData: FirmPageLoader,
    },
    FirmDashBoardPage: {
      loadData: FirmDashBoardPageLoader,
    },
    FirmMemberExpertsPage: {
      loadData: FirmMemberExpertsPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    FavouritesPage: {
      loadData: FavouritesPageLoader,
    },
    GigAppsTransactionPage: {
      loadData: GigAppsTransactionPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    InvitationPage: {
      loadData: InvitationPageLoader,
    },
    IntegrationPage: {
      loadData: IntegrationPageReducer,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    StripeSubscriptionPage: {
      loadData: StripeSubscriptionPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    ComparisonPage: {
      loadData: ComparisonPageLoader,
    },
    DiscourseCommunityForumPage: {
      loadData: DiscourseCommunityForumPageLoader,
    }
  };
};

export default getPageDataLoadingAPI;

