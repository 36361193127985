import { v4 as uuidv4 } from 'uuid';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  ADD_MILESTONE,
  AI_QUERY_REPORT,
  CHAT_NOTIFICATION,
  COMPLETE_MILESTONE,
  DELETE_MILESTONE,
  EDIT_MILESTONE,
  GIG_APP_COMPLETED,
  HISTORY_MILESTONES_COMPLETED,
  HISTORY_MILESTONES_CREATED,
  HISTORY_MILESTONES_DELETED,
  HISTORY_MILESTONES_UNCOMPLETED,
  HISTORY_MILESTONES_UPDATED,
  INCOMPLETE_MILESTONE,
  INVITE_STATUS_ACTIVE,
  MESSAGE,
  QUERY_REPORT,
  SELECT_MILESTONE,
  USER_ROLE_CLIENT,
  USER_ROLE_CSM,
  USER_ROLE_PARTNER,
} from '../../../util/types';
import {
  sendChatNotificationEmail,
  storeNotificationInDb,
} from '../../../containers/TransactionPage/TransactionPage.duck';
import { ensureUser } from '../../../util/data';
import { getUserDetails } from '../../../util/destructorHelpers';
import {
  apiBaseUrl,
  deleteNotificationFromDB,
  handleFileUpload,
  addGigAppSession,
  updateFirmUserDetails,
} from '../../../util/api';
import axios from 'axios';
import {
  fetchMessageResponse,
  getAndRemoveNotifications,
  getAppTransactionFromMongoDB,
  upsertMilestone,
  upsertMilestoneThroughIsdk,
} from '../../../containers/GigAppsTransactionPage/GigAppsTransactionPage.duck';
import {
  TRANSITION_INITIATED_ASSISTED_FLOW,
  TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED,
  TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_MILESTONE,
  TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_ASSISTED,
  TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_MILESTONE,
  handleTransactionHistory,
} from '../../../util/transaction';
import { sendEmailUsingZepto } from '../../../util/api';
const { UUID } = sdkTypes;

export const sendNotification = async params => {
  const { message, currentTransaction, currentUser } = params;
  const txId = currentTransaction?.id?.uuid;
  const ensuredCustomer = currentTransaction?.id && ensureUser(currentTransaction?.customer);
  const ensuredProvider = currentTransaction?.id && ensureUser(currentTransaction?.provider);
  const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
  const collaborationMetaData =
    currentTransaction?.id && currentTransaction?.attributes?.metadata?.collaborationMetaData
      ? currentTransaction?.attributes?.metadata?.collaborationMetaData
      : [];
  try {
    const notificationObject = {
      notificationType: CHAT_NOTIFICATION,
      transactionId: txId,
      notificationUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/details`,
      content: message,
    };
    const customerId = ensuredCustomer.id && getUserDetails(ensuredCustomer)?.id;
    const providerId = ensuredProvider.id && getUserDetails(ensuredProvider)?.id;
    const activeCollaborators = collaborationMetaData?.filter(
      ({ email, status }) => email !== currentUserEmail && status === INVITE_STATUS_ACTIVE
    );
    // Combine and filter customer and provider IDs
    const recipientIds = [
      customerId,
      providerId,
      ...(activeCollaborators || []).map(collaborator => collaborator.collaboratorID),
    ].filter(id => id !== currentUser.id.uuid);
    // Loop through recipient IDs
    for (const userId of recipientIds) {
      // Send notification with user-specific content
      await storeNotificationInDb({
        ...notificationObject,
        userId: userId,
      });
    }
  } catch (error) { }
};

export async function onMessageSubmit(params) {
  const {
    values,
    form,
    currentUserEmail,
    currentTransaction,
    chainedTransactionId,
    socket,
    currentUser,
    dispatch,
    authorName,
    authorEmail,
    userName,
    currentPath,
    connectedUsers,
  } = params;
  let { message, attachmentsUrls = [] } = values || {};
  const { link: url, name, date } = attachmentsUrls?.length && attachmentsUrls[0];
  let tagsEmails = [];
  const mentionRegex = /@(\w+)/g;
  const mentionedUsers = message?.match(mentionRegex);
  const onlineUsers = Object.values(connectedUsers || {});

  if (mentionedUsers?.length) {
    if (mentionedUsers.includes(`@everyone`)) {
      tagsEmails = mentionedUsers.length
        ? membersData
          .filter(member => member.email !== currentUserEmail)
          .map(member => member.email)
        : [];
    } else if (mentionedUsers.includes(`@here`)) {
      tagsEmails = onlineUsers?.length
        ? membersData
          ?.filter(member => {
            if (member.email === currentUserEmail) return;
            return onlineUsers?.find(user => user.email === member.email);
          })
          ?.map(member => member.email)
        : [];
    } else if (mentionedUsers.includes(`@nothere`)) {
      tagsEmails = onlineUsers?.length
        ? membersData
          .filter(member => !onlineUsers.some(user => user.email === member.email))
          .map(member => member.email)
        : [];
    } else {
      const emails = mentionedUsers.length
        ? membersData
          .filter(member => {
            if (member.email === currentUserEmail) return false;
            const fullName = member.fullName?.replace(/\s+/g, '_')?.toLowerCase();
            return mentionedUsers.some(user => user.substring(1) === fullName);
          })
          .map(member => member.email)
        : [];
      tagsEmails = [...new Set([...emails])];
    }
  }

  if (url) message = url;
  const messageText = message ? message.trim() : null;

  if (!messageText) return;
  const messageId = new UUID(uuidv4());
  socket?.current?.emit('message', {
    attributes: {
      content: message,
      createdAt: Date.now(),
      fileName: name,
    },
    id: messageId,
    transactionId: currentTransaction?.id?.uuid,
    chainedTransactionId: chainedTransactionId
      ? chainedTransactionId
      : currentTransaction?.id?.uuid,
    socketId: `${socket?.current?.id}${Math.random()}`,
    isReadBy: [],
    sender: {
      attributes: currentUser?.attributes,
      id: currentUser?.id,
      profileImage: currentUser?.profileImage,
    },
    type: MESSAGE,
  });
  socket?.current?.removeAllListeners('messageResponse');
  socket?.current?.on('messageResponse', data => dispatch(fetchMessageResponse(data)));
  form.reset();

  const filteredUsersEmail = [...new Set([...tagsEmails])];
  sendNotification({ message, currentTransaction, currentUser });
  if (!!filteredUsersEmail?.length) {
    dispatch(
      sendChatNotificationEmail({
        authorName,
        authorEmail,
        userName,
        userEmail: filteredUsersEmail,
        currentPath,
        message: url ? { name, url } : { text: message },
        step: CHAT_NOTIFICATION,
      })
    );
  }
}

export function onChangeMilestone(params) {
  const { transaction, milestone, currentUser, emails, dispatch, isCollaborator } = params || {};
  const txId = transaction.id.uuid;
  const lastTransition = transaction?.attributes?.lastTransition;
  const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
  const emailParams = {
    userEmail: emails.filter(email => email != currentUserEmail),
    providerName: currentUser?.id && currentUser?.attributes?.profile?.firstName,
    currentPath: typeof window !== 'undefined' && window.location.href,
    customerName: transaction.id && getUserDetails(transaction?.customer).firstName,
    lastTransition,
  };
  const actions = {
    add: ADD_MILESTONE,
    edit: EDIT_MILESTONE,
    select: SELECT_MILESTONE,
  };

  const { milestones = [] } = transaction?.attributes?.metadata || {};
  const existenMilestonIndex = milestones.findIndex(m => m && m.id === milestone.id);
  const dependentMilestone = milestones.find(
    m => m.dependency && JSON.parse(m.dependency).id === milestone.id
  );
  if (dependentMilestone) {
    dependentMilestone.dependency = JSON.stringify(milestone);
  }

  const validIndex = existenMilestonIndex > -1;

  let bodyParams = {
    id: transaction.id.uuid,
  };

  const sender = currentUser.id.uuid;

  if (!validIndex) {
    const history = handleTransactionHistory(transaction, {
      sender,
      content: HISTORY_MILESTONES_CREATED,
      data: { title: milestone.title },
    });
    bodyParams = {
      ...bodyParams,
      metadata: {
        isTransactionRead: false,
        milestones: [...milestones, milestone],
        history,
      },
      notification: {
        type: actions.add,
      },
      currentUser,
      transaction,
      emailParams,
    };
  } else if (validIndex) {
    const history = handleTransactionHistory(transaction, {
      sender,
      content: HISTORY_MILESTONES_UPDATED,
      data: { title: milestone.title },
    });

    milestones[existenMilestonIndex] = milestone;
    bodyParams = {
      ...bodyParams,
      metadata: {
        isTransactionRead: false,
        milestones,
        history,
      },
      notification: {
        type: actions.edit,
      },
      currentUser,
      transaction,
      emailParams,
    };
  }
  Object.assign(emailParams, { isCollaborator });
  return isCollaborator
    ? dispatch(upsertMilestoneThroughIsdk(bodyParams))
    : dispatch(upsertMilestone(bodyParams));
}

export function onDeleteMilestone(params) {
  try {
    const { transaction, milestoneId, currentUser, emails, dispatch, isCollaborator } = params;
    const currentUserEmail = currentUser?.id && getUserDetails(currentUser)?.email;
    const { lastTransition } = transaction?.attributes || '';
    const emailParams = {
      userEmail: emails.filter(email => email != currentUserEmail),
      providerName: currentUser?.id && currentUser?.attributes?.profile?.firstName,
      currentPath: typeof window !== 'undefined' && window.location.href,
      lastTransition,
    };
    const { milestones } = transaction?.attributes?.metadata || {};
    const history = handleTransactionHistory(transaction, {
      sender: currentUser.id.uuid,
      content: HISTORY_MILESTONES_DELETED,
      data: { title: milestones.find(m => m.id === milestoneId).title },
    });
    const newMilestonesList = milestones.filter(({ id }) => id !== milestoneId);
    const dependentMilestone = milestones.find(
      m => m.dependency && JSON.parse(m.dependency).id === milestoneId
    );
    if (dependentMilestone) {
      dependentMilestone.dependency = null;
    }
    const bodyParams = {
      id: transaction.id.uuid,
      metadata: {
        isTransactionRead: false,
        milestones: newMilestonesList.length ? newMilestonesList : null,
        history,
      },
      notification: {
        type: DELETE_MILESTONE,
      },
      currentUser,
      transaction,
      emailParams,
    };
    Object.assign(emailParams, { isCollaborator });
    return isCollaborator
      ? dispatch(upsertMilestoneThroughIsdk(bodyParams))
      : dispatch(upsertMilestone(bodyParams));
  } catch (error) { }
}

export function onToggleMilestone(params) {
  const {
    isCollaborator,
    transaction,
    txId,
    milestoneId,
    currentUser,
    emailParams,
    isSelected,
    dispatch,
  } = params || {};

  const { milestones } = transaction?.attributes?.metadata || {};

  const history = handleTransactionHistory(transaction, {
    sender: currentUser.id.uuid,
    content: isSelected ? HISTORY_MILESTONES_COMPLETED : HISTORY_MILESTONES_UNCOMPLETED,
    data: { title: milestones.find(m => m.id === milestoneId).title },
  });
  const milestonIndex = milestones.findIndex(({ id }) => id === milestoneId);
  milestones[milestonIndex].selected = isSelected ? ['selected'] : [];
  const bodyParams = {
    id: txId,
    metadata: {
      isTransactionRead: false,
      milestones,
      history,
    },
    notification: {
      type: isSelected ? COMPLETE_MILESTONE : INCOMPLETE_MILESTONE,
    },
    transaction,
    currentUser,
    emailParams,
  };
  Object.assign(emailParams, { isCollaborator });
  return isCollaborator
    ? dispatch(upsertMilestoneThroughIsdk(bodyParams))
    : dispatch(upsertMilestone(bodyParams));
}

// HandleTranscriptionGenerator
export async function handleTranscriptionTransaction(params) {
  const {
    values,
    setOpenaiRequest,
    handleError,
    transactionId,
    boxFolder,
    setGigAppModalState,
    dispatch,
    setOpenSuccessModal,
    email,
    appRoute,
  } = params;

  const { file, input = '', runName } = values || {};
  const chunkSize = 40 * 1024 * 1024; // 40 MB chunks
  try {
    setOpenaiRequest(true);

    //Update mongo database with new session
    const response = await addGigAppSession({ 
      transactionId, email, appRoute,
      runName, 
      rootFolderId: boxFolder.rootFolderId, 
      data: {inputText: input}, 
      files: [{fileName: file.name, name: file.name, size: file.size}] 
    });

    setOpenaiRequest(false);
    setGigAppModalState(false);
    setOpenSuccessModal(true);
    dispatch(getAppTransactionFromMongoDB(transactionId));
    
    let offset = 0;
    const params = {
      inputText: input,
      sessionId: response?.sessionId
    }

    while (offset < file.size) {
      const chunk = file.slice(offset, offset + chunkSize);
      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('fileName', file?.name);
      
      offset += chunkSize;
      if(offset > file.size) formData.append('params', JSON.stringify(params));
      // Send chunk to server
      await axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/gigapp_transaction_transcription`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
    }
  } catch (error) {
    console.log(error, 'error')
    handleError(error.message); // Pass the full error object for better handling
  }
}

// HandleReportIdeaGeneration
export async function handleReportIdeaTransaction(params){
  const { values, setOpenaiRequest, handleError, transactionId, boxFolder,
    setGigAppModalState, setOpenSuccessModal, email, firstName, dispatch, title, appRoute
  } = params;
  try{
    setOpenaiRequest(true);
    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) //add 30 days
    const {proposalDocumentText, proposalDocument, transcription, runName} = values;

    // Update mongo database with new session and files data
    const modifiedFilesData = [transcription].map(file => ({fileName: file.name, name: file.name, size: file.size}))
    const response = await addGigAppSession({ email, appRoute, expiresAt, rootFolderId: boxFolder.rootFolderId, transactionId, runName, files: modifiedFilesData });

    const formData = new FormData();
    formData.append('proposalText', !!proposalDocumentText ? proposalDocumentText : '');
    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('txId', transactionId);
    formData.append('runName', runName);
    formData.append('title', title);
    formData.append('sessionId', response?.sessionId);

    formData.append("file1", transcription);
    proposalDocument?.name && formData.append("file2", proposalDocument);

    axios.post(`${apiBaseUrl()}/api/report-idea-transaction`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    setTimeout(() => {
      setOpenaiRequest(false);
      setGigAppModalState(false);
      setOpenSuccessModal(true);
      dispatch(getAppTransactionFromMongoDB(transactionId));
    }, 3000)
  } catch(error){
    setOpenaiRequest(false);
    handleError(error.message);
  }
}

// Handle Query Report Transaction
export async function handleQueryReportTransaction(params) {
  const {
    values,
    setOpenaiRequest,
    setOpenSuccessModal,
    handleError,
    email,
    firstName,
    boxFolder,
    transactionId,
    setGigAppModalState,
    dispatch,
    appRoute,
    title
  } = params || {};
  try {
    setOpenaiRequest(true);
    const {folders, runName} = values || {};
    const formData = new FormData();
    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) //add 30 days 

    const payload = await Promise.all(
      folders.map(async item => {
        const fetchedContentFolders = await Promise.all(
          item.folderFiles.map(async file => {
            const response = await handleFileUpload('get-parsed-document', { file });
            formData.append('files', file);

            return {
              context: response?.data,
              file: {
                fileName: file.name,
                size: file.size,
                createdAt: file.lastModified,
              },
            };
          })
        );
        return {
          folderName: item.folderName,
          folderFiles: fetchedContentFolders,
        };
      })
    );

    const transformFiles = (folder) => folder.folderFiles.map(file => ({
      fileName: file.name,
      size: file.size,
      createdAt: file.lastModified,
    }));

    const transformedData = folders.length > 1 ? 
    {
      multipleFolders: true,
      folders: folders.map(folder => ({
        folderName: folder.folderName,
        files: transformFiles(folder),
      })),
    } : 
    {
      multipleFolders: false,
    };
    
    // Update mongo database with new session
    const response = await addGigAppSession({ email, appRoute, runName, transactionId, expiresAt,
      rootFolderId: boxFolder.rootFolderId, 
      files: folders.flatMap(folder => transformFiles(folder)), 
      data: transformedData
    });

    // Generate summary of uploaded document text and store it in session
    fetch(`${apiBaseUrl()}/api/query-report-summary`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      credentials: 'include',
      body: JSON.stringify({payload, id: response?.sessionId, transactionId, email, title, runName})
    })

    formData.append('email', email)
    formData.append('firstName', firstName)
    formData.append('sessionId', response?.sessionId)
    formData.append('transactionId', transactionId)
    formData.append('appRoute', appRoute)
    formData.append('runName', runName)
    
    // Send file to django server for processing
    fetch(`${process.env.REACT_APP_DJANGO_BACKEND_API}/query_report`, {
      method: 'POST',
      headers: { },
      body: formData, // Pass the form data in the body
    })

    setTimeout(() => {
      setOpenaiRequest(false);
      setGigAppModalState(false);
      setOpenSuccessModal(true);
      dispatch(getAppTransactionFromMongoDB(transactionId));
    }, 3000)
  } catch (error) {
    handleError(error.message);
    setOpenaiRequest(false);
    setOpenSuccessModal(false);
  }
}

export async function handleAnalyzeRawDataTransaction(params){
  const {values, handleError, email, setOpenaiRequest, setAnalyzeFileError, firstName, setGigAppModalState, transactionId, appRoute, boxFolder, dispatch, title} = params;

  try{
    setOpenaiRequest(true)
    const { file, questionnaireDoc, questionnaireFile, runName } = values || {};

    const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30) //expire after 30 days

    let modifiedFiles = []
    if(file?.name) modifiedFiles.push({fileName: file.name, name: file.name, size: file.size})
    if(questionnaireFile?.name) modifiedFiles.push({fileName: questionnaireFile.name, name: questionnaireFile.name, size: questionnaireFile.size})

    const appSessionResponse = await addGigAppSession({
      runName, email, appRoute,
      transactionId, 
      expiresAt, 
      files: modifiedFiles, 
      rootFolderId: boxFolder.rootFolderId, 
      data : {
        questionnaireFile : {name: questionnaireFile.name,size: questionnaireFile.size, createdAt: Date.now()},
        xlsxFile: {name: file.name,size:file.size, createdAt: Date.now()},
        queryData: [],
        inputsUsed:false,
        firstName,
      } 
    });

    // Call server api to process input file and create thread
    const response = handleFileUpload('analyzeRawQuantitativeDataGenerator', {
      contentFile: file,
      questionnaireFile,
      email,
      questionnaireDoc,
      id: appSessionResponse?.sessionId,
      firstName,
      assisted: true,
      transactionId,
      appRoute,
      removeFile: true,
      runName,
      title
    });

    setTimeout(() => { 
      setAnalyzeFileError(false)
      setOpenaiRequest(false)
      setGigAppModalState(false)
      dispatch(getAppTransactionFromMongoDB(transactionId));
    }, 3000)
  }catch(error){
    handleError(error.message);
  }
}

export async function handleAnalyzeRawThreadTransaction(params){
  const {values, handleError, email, setOpenaiRequest, firstName, transactionId, appRoute, setGigAppModalState, dispatch} = params;
  
  try{
    setOpenaiRequest(true)
    const { questionsList, paramsId, threadId, fileExtension, runName } = values || {};

    const param = {
      questionsList,
      firstName,
      paramsId,
      email,
      threadId,
      fileExtension,
      transaction: true,
      transactionId,
      appRoute,
      runName
    }

    const response = await fetch(`${apiBaseUrl()}/api/analyzeRawQuantitativeThreadGenerator`,{
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Transfer-Encoding': 'chunked',
      },
      body: JSON.stringify(param)
    })
    if(response.status === 200){
      setOpenaiRequest(false)
      setGigAppModalState(false)
      dispatch(getAppTransactionFromMongoDB(transactionId));
      updateFirmUserDetails({ action: GIG_APP_COMPLETED, userEmail: email, appName: appRoute })
    }
  }
  catch(error){
    console.log(error, 'Error handling question list to generate openai file!')
    handleError(error)
  }
}

// Handle Sentiement Analysis Transaction
export async function handleSentimentAnalysisTransaction(params) {
  const {
    values,
    setOpenaiRequest,
    handleError,
    transactionId,
    boxFolder,
    setGigAppModalState,
    dispatch,
    setOpenSuccessModal,
    email,
    firstName,
    appRoute,
  } = params;

  try {
    const { file, keywordRange, runName} = values || {};
    setOpenaiRequest(true);

    //Update mongo database with new session
    const response = await addGigAppSession({
      runName, email, appRoute,
      transactionId, 
      rootFolderId: boxFolder.rootFolderId, 
      files: [{fileName: file.name, name: file.name, size: file.size}] 
    });

    setTimeout(() => {
      setOpenaiRequest(false);
      setGigAppModalState(false);
      setOpenSuccessModal(true);
      dispatch(getAppTransactionFromMongoDB(transactionId));
    }, 3000)

    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', email);
    formData.append('firstName', firstName)
    formData.append('sessionId', response?.sessionId)
    formData.append('transactionId', transactionId)
    formData.append('runName', runName)
    if (!!keywordRange) formData.append('keywordRange', keywordRange)

    axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/combined_topic_sentiment`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  } catch (error) {
    console.log(error, 'error')
    handleError(error.message); // Pass the full error object for better handling
  }
}

export async function markNotificationAsRead(params) {
  const { dispatch, currentUser, transactionId } = params;
  const response = await deleteNotificationFromDB({
    userId: currentUser.id.uuid,
    transactionId,
  });
  if (response.success === 'OK') {
    dispatch(getAndRemoveNotifications(currentUser, transactionId));
  }
}

export function bufferToFile(buffer, filename) {
  let fileType = '';
  const extension = filename.split('.').at(-1);
  if (extension === 'mp4') fileType = 'video/mp4';
  if (extension === 'mp3') fileType = 'audio/mpeg';
  const typedArray = new Uint8Array(buffer);
  const blob = new Blob([typedArray], { type: fileType });
  const fileObject = new File([blob], filename, { type: fileType });
  return fileObject;
}

// setTransitionForProjectClosure
export function setTransitionForProjectClosure(params) {
  const { userRole, lastTransition } = params || {};
  const transitionMap = {
    [USER_ROLE_CLIENT]: {
      [TRANSITION_INITIATED_ASSISTED_FLOW]: TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED,
      default: TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_MILESTONE,
    },
    [USER_ROLE_PARTNER]: {
      [TRANSITION_INITIATED_ASSISTED_FLOW]: TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_ASSISTED,
      default: TRANSITION_REQUEST_TRANSACTION_COMPLETE_CUSTOMER_MILESTONE,
    },
    [USER_ROLE_CSM]: {
      [TRANSITION_INITIATED_ASSISTED_FLOW]: TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_ASSISTED,
      default: TRANSITION_REQUEST_TRANSACTION_COMPLETE_PROVIDER_MILESTONE,
    },
  };

  return transitionMap[userRole]?.[lastTransition] || transitionMap[userRole]?.default;
}

// createHTMLElement
export function createHTMLElement() {
  const htmlString = document.getElementById('chatBox').innerHTML;
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body;
}

export function stringContainsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}
